<template>
  <div class="bg">
    <div class="dimmed"></div>
    <div class="top">
      <img src="@/assets/FireScoutLogo_white.svg" style="max-width: 120px; margin-top: 20px; margin-right: 25px" />
    </div>
    <div class="middle">
      <div class="title-container">
        <div class="text-title">Welcome Back!</div>
        <div class="text-subtitle">Wildfire Alert System</div>
      </div>
      <v-form ref="form" v-model="valid" class="middle">
        <InputLogin
          v-model="username"
          ref="username"
          @input="isLoginFailed = false"
          placeholder="ID"
          :error="isLoginFailed"
          :rules="rules.id"
          style="margin-top: 42px; min-width: 260px"
        ></InputLogin>
        <InputLogin
          v-model="password"
          type="Password"
          ref="password"
          @input="isLoginFailed = false"
          placeholder="Password"
          :error="isLoginFailed"
          :rules="rules.password"
          style="margin-top: 19px; min-width: 260px"
        ></InputLogin>
        <div class="middle text-error" :style="{ visibility: isLoginFailed ? 'visible' : 'hidden' }">Incorrect ID or Password</div>
        <div class="d-flex align-center align-self-end" style="margin-bottom: 16px">
          <div class="text-keep-login">Keep me logged in</div>
          <ToggleLogin v-model="keepLogin" @input="onChangeKeepLogin" style="margin-right: 30px"></ToggleLogin>
        </div>
        <v-btn type="submit" @click.prevent="onClickLoginButton" color="#204687" class="middle button" rounded>Login</v-btn>
      </v-form>
    </div>
    <div class="bottom">
      <div class="d-flex flex-column" style="align-items: center">
        <div class="d-flex" style="margin-bottom: 5px">
          <div>Camera images provided by</div>
          <img src="@/assets/wildfire-logo-white.png" height="22px" style="margin-left: 3px; margin-top: -9px" />
          <img src="@/assets/AlertCA_Logo_White.svg" width="110px" style="margin-left: 3px; margin-top: -9px" />
        </div>
        <div class="d-flex" style="margin-bottom: 30px">
          <div>For business inquiry,</div>
          <a href="mailto:contact@alcherainc.com" target="_blank" class="email">contact@alcherainc.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleLogin from '@mobile/ToggleLogin'
import InputLogin from '@mobile/InputLogin'
import rules from '@/rules'
import RotuerMixin from '@/mixin/router.mixin'
import { auth } from '@/api'

export default {
  mixins: [RotuerMixin],
  components: { ToggleLogin, InputLogin },
  data() {
    return {
      rules: rules,
      valid: true,
      username: '',
      password: '',
      isLoginFailed: false,
      keepLogin: true,
    }
  },
  created() {
    if (auth.isAuth()) {
      this.$router.replace('/')
    }
    auth.keepLogin(true)
  },
  methods: {
    onChangeKeepLogin(e) {
      auth.keepLogin(e)
    },
    async onClickLoginButton() {
      // iphone의 경우 키체인에 의한 자동완성의 경우 아무런 이벤트가 발생되지 않기 때문에 강제로 validate를 작동시켜야 함
      const validUsername = this.$refs.username.validate()
      const validPassword = this.$refs.password.validate()
      if (validUsername && validPassword) {
        try {
          this.isLoginFailed = false
          await this.$store.dispatch('login', { username: this.username, password: this.password })
          this.$gtag.customMap({ 'User ID': this.username })
        } catch (e) {
          this.isLoginFailed = true
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/group 4023.jpg');
  width: 100%;
  height: 100%;
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dimmed {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.15;
  z-index: -1;
}

.top {
  flex-grow: 1;
  text-align: right;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    background-color: #25262c !important;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .text-error {
    color: #ff3b30;
    font-size: 12px;
    min-width: 260px;
    margin-bottom: 5px;
  }

  .text-keep-login {
    color: #ffffffde;
    font-size: 11px;
    margin-right: 5px;
  }

  .button {
    font-size: 14px;
    font-weight: 500;
    background-color: #3d51fb !important;
    width: 200px;
    height: 36px !important;
    margin-bottom: 40px;
  }
}

.bottom {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-weight: 400;
  font-size: 11px;
  color: #ffffffde;

  .email {
    color: #ffffffde;
    text-decoration: underline;
    margin-left: 3px;
  }
}

.title-container {
  width: 100%;
  max-width: 320px;
  margin-bottom: 20px;
  margin-top: -50px;

  .text-title {
    color: #ffffffde;
    font-size: 14px;
    font-weight: 600;
  }

  .text-subtitle {
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
