<template>
  <div>
    <input
      :type="type"
      :placeholder="placeholder"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :class="{ empty, disabled, 'error-status': erroFlag }"
      :disabled="disabled"
    />
    <div :class="{ 'bottom-line': true, focus, disabled, error: erroFlag }"></div>
    <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
  </div>
</template>

<script>
import InputLogin from '@common/InputLogin'

export default {
  extends: InputLogin,
}
</script>

<style lang="scss" scoped>
input {
  outline: none;
  padding: 0 8px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  caret-color: #3b77ff;
  color: #ffffffde;

  &.empty {
    color: #ffffff99;
  }

  &.disabled {
    color: #ffffff61;
  }

  &.error-status {
    color: #ff3b30;
  }
}

.error-status-text {
  margin-top: 2.5px;
  font-size: 10px;
  font-weight: 400;
  padding: 0 8px;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
}

.bottom-line {
  width: 100%;
  margin-top: 4.5px;
  border-bottom: 1px solid white;

  &.focus {
    border-bottom: 1px solid #3b77ff;
  }

  &.disabled {
    border-bottom: 1px dashed #ffffff61;
  }

  &.error {
    border-bottom: 1px solid #ff3b30;
  }
}
</style>
