<template>
  <div @click="onClickToggle" class="toggle-login">
    <div :class="{ 'toggle-login-bg': true, on: value }">
      <div :class="{ 'toggle-login-switch': true, on: value }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  methods: {
    onClickToggle() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-login {
  cursor: pointer;
}

.toggle-login-bg {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #8d90a2;
  border-radius: 18px;
  width: 34px;
  height: 18px;

  &.on {
    background-color: #3d51fb;
  }
}

.toggle-login-switch {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 16px;
  transition: transform 0.3s;
  transform: translateX(calc(30px - 13.3px));

  &.on {
    transform: translateX(1.3px);
  }
}
</style>
