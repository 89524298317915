<template>
  <div>
    <input
      :type="type"
      :placeholder="placeholder"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :class="{ empty, disabled, 'error-status': erroFlag }"
      :disabled="disabled"
    />
    <div :class="{ 'bottom-line': true, focus, disabled, error: erroFlag }"></div>
    <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      erroFlag: this.error,
      errorMessage: '',
    }
  },
  computed: {
    empty() {
      return !this.text.length
    },
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  watch: {
    value(val) {
      this.text = val
    },
    error(val) {
      this.erroFlag = val
    },
  },
  methods: {
    validate(text) {
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            this.erroFlag = true
            this.errorMessage = error
            break
          } else {
            this.erroFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.erroFlag
    },
    onInput(e) {
      this.text = e.target.value
      this.validate()
      this.$emit('input', this.text)
    },
    onBlur() {
      this.focus = false
    },
    onFocus() {
      this.focus = true
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  outline: none;
  padding: 0 8px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  caret-color: #3b77ff;
  color: #ffffffde;

  &.empty {
    color: #ffffff99;
  }

  &.disabled {
    color: #ffffff61;
  }

  &.error-status {
    color: #ff3b30;
  }
}

.error-status-text {
  margin-top: 2.5px;
  font-size: 10px;
  font-weight: 400;
  padding: 0 8px;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
}

.bottom-line {
  width: 100%;
  margin-top: 4.5px;
  border-bottom: 1px solid white;

  &.focus {
    border-bottom: 1px solid #3b77ff;
  }

  &.disabled {
    border-bottom: 1px dashed #ffffff61;
  }

  &.error {
    border-bottom: 1px solid #ff3b30;
  }
}
</style>
